export const activitiesAppParameters = {
  clientBookingId: "",
  startDate: null,
  endDate: null,
  location: "",
  totalTickets: "",
  businessTrip: "",
  withChildren: "",
  ages: {
    age1: "",
    age2: "",
    age3: "",
    age4: "",
  },
  language: "",
  productId: "",
  currency: "",
  currencyExchange: "",
  /* travellers: [
    {
      name: "Contact",
      surname: "Traveller Test",
      email: "globickuseremail@gmail.com",
      countryPhoneCode: "+34",
      phone: "1122334455",
    },
    {
      name: "FirstName",
      surname: "First Traveller",
      email: "globickuseremail@gmail.com",
      dob: "1983-06-21",
      address: "C/ First Traveller, 1",
      country: "ES",
      countryPhoneCode: "+34",
      phone: "123456789",
      countryMobileCode: "+34",
      mobile: "987654321",
      language: "ENG",
      nationality: "ES",
      passport: "74185296Q",
      expenditureDate: "2019-12-31",
      expirationDate: "2020-12-31",
    },
    {
      name: "SecondName",
      surname: "Second Traveller",
      email: "globickuseremail@gmail.com",
      dob: "1992-05-11",
      address: "C/ Second Traveller, 1",
      country: "ES",
      countryPhoneCode: "+34",
      phone: "741852963",
      countryMobileCode: "+34",
      mobile: "321654987",
      language: "ENG",
      nationality: "ES",
      passport: "75386942L",
      expenditureDate: "2019-11-31",
      expirationDate: "2020-11-31",
    },
  ], */
};

export const transfersAppParameters = {
  clientBookingId: "",
  language: "",
  currency: "",
  currencyExchange: "",
  airline: "",
  returnAirline: "",
  flightNumber: "",
  returnFlightNumber: "",
  outboundTripDateTime: null,
  returnTripDateTime: null,
  pickupLocation: "",
  productId: "",
 /*  travellers: [
    {
      firstName: "FirstName",
      lastName: "First Traveller",
      contactEmail: "globickuseremail@gmail.com",
      phoneNumberCountryIsoCode: "ES",
      phoneNumber: "664887234",
    },
  ], */
};

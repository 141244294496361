import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";

export const tabProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export const CustomTabs = withStyles({
  root: {
    backgroundColor: "#fff",
    color: "black",
  },
  indicator: {
    backgroundColor: "#0080b5",
    height: "3px",
  },
})(Tabs);

import React, { useEffect } from "react";
import { encode as base64_encode } from "base-64";

import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { activitiesAppParameters } from "../../appParameters";
import formatAges from "../../functions/formattedAges";
import { formatActivitiesDate } from "../../functions/formattedDate";
import useForm from "../../hooks/useForm";
import { Button, ButtonGroup } from "@material-ui/core";
import { clientsByEnvironment } from "../../utils/clientsByEnvironment";
import { getToken } from "../../functions/getToken";

const {
	REACT_APP_DEV_ACTIVITIES_TOKEN_URL,
	REACT_APP_PRE_ACTIVITIES_TOKEN_URL,
	REACT_APP_PROD_ACTIVITIES_TOKEN_URL,
	REACT_APP_CLIENT_ID,
	REACT_APP_SECRET_ID,
} = process.env;

const Activities = (props) => {
	const {
		isWidgetOpen,
		setIsWidgetOpen,
		postOrderCallback,
		confirmOrderCallback,
		getOrdersCallback,
		cancelOrderCallback,
		expiredTokenCallback,
	} = props;

	const [token, setToken] = React.useState("");
	const [environment, setEnvironment] = React.useState(window.localStorage.getItem("envActivities") || "");
	const [authUrl, setAuthUrl] = React.useState("");
	const [client, setClient] = React.useState(window.localStorage.getItem("clientActivities") || "");
	const [clientSecret, setClientSecret] = React.useState("");
	const [isMMB, setIsMMB] = React.useState(false);

	const [formValues, handleInputChange, reset] = useForm(activitiesAppParameters);

	const {
		clientBookingId,
		startDate,
		endDate,
		location,
		totalTickets,
		businessTrip,
		withChildren,
		language,
		currency,
		currencyExchange,
		travellers,
		ages,
		productId,
	} = formValues;

	const getRecommendationCallback = (activities) => {
		console.log(activities);
	};

	const mountRecommenderWidget = (formattedAges) => {
		window.RecoglobickActivitiesWidget.unmount();
		window.RecoglobickActivitiesWidget.mount({
			env: environment.toLowerCase(),
			token: `Bearer ${token}`,
			clientBookingId: clientBookingId.trim(""),
			startDate: startDate ? formatActivitiesDate(startDate) : null,
			endDate: endDate ? formatActivitiesDate(endDate) : null,
			location: location.trim(""),
			totalTickets: totalTickets ? totalTickets.trim("") : null,
			businessTrip: businessTrip ? Boolean(businessTrip) : null,
			withChildren: withChildren ? Boolean(withChildren) : null,
			ages: formattedAges && formattedAges.length ? formattedAges : null,
			language: language ? language.trim("") : "en-US",
			productId: productId ? productId.trim("") : null,
			currency: currency ? currency.trim("") : "EUR",
			currencyExchange: currencyExchange ? currencyExchange.trim("") : 1,
			travellers,
			gaTrackingCode: "test",
			postOrderCallback: postOrderCallback,
			getRecommendationCallback: getRecommendationCallback,
			confirmOrderCallback: confirmOrderCallback,
			expiredTokenCallback: expiredTokenCallback,
		});
	};

	const updateManagementWidget = () => {
		window.RecoglobickActivitiesMmbWidget.unmount();
		window.RecoglobickActivitiesMmbWidget.mount({
			env: environment.toLowerCase(),
			token: `Bearer ${token}`,
			bookingId: clientBookingId.trim(""),
			language: language ? language.trim("") : "en-US",
			currency: currency ? currency.trim("") : "EUR",
			currencyExchange: currencyExchange ? currencyExchange.trim("") : 1,
			productId: productId ? productId.trim("") : null,
			gaTrackingCode: "test",
			getOrdersCallback: getOrdersCallback,
			cancelOrderCallback: cancelOrderCallback,
			expiredTokenCallback: expiredTokenCallback,
		});
	};

	useEffect(() => {
		window.localStorage.setItem("envActivities", environment);
		switch (environment) {
			case "DEV":
				setAuthUrl(REACT_APP_DEV_ACTIVITIES_TOKEN_URL);
				break;
			case "PRE":
				setAuthUrl(REACT_APP_PRE_ACTIVITIES_TOKEN_URL);
				break;
			case "PROD":
				setAuthUrl(REACT_APP_PROD_ACTIVITIES_TOKEN_URL);
				break;
			default:
				break;
		}
	}, [environment]);

	const setEncodedCredentials = () => {
		if (environment === "PROD" && !clientSecret) return;

		const clientId = clientsByEnvironment[environment][client].clientId || REACT_APP_CLIENT_ID;
		const secret = clientSecret || clientsByEnvironment[environment][client].secretId || REACT_APP_SECRET_ID;
		let encodedCredentials = base64_encode(clientId + ":" + secret);

		return encodedCredentials;
	};

	const getApiTokens = () => {
		getToken(authUrl, setEncodedCredentials())
			.then((response) => {
				setToken(response.access_token);
			})
			.catch(console.error);
	};

	const onActivitiesSubmit = () => {
		if (!clientBookingId || !startDate || !location || !totalTickets) return;

		setIsMMB(false);
		getApiTokens();
	};

	useEffect(() => {
		if (!isMMB) {
			if (!token || !clientBookingId || !startDate || !location || !totalTickets) return;

			setIsWidgetOpen(true);
			mountRecommenderWidget(formatAges(ages));
		} else {
			if (!token || !clientBookingId) return;

			setIsWidgetOpen(true);
			updateManagementWidget();
		}
	}, [token]);


	const onManageSubmit = () => {
		if (!clientBookingId) return;

		setIsMMB(true);
		getApiTokens();
	};

	const onReset = () => {
		setEnvironment("");
		setClient("");
		setClientSecret("");
		reset(activitiesAppParameters);
	};

	return (
		<>
			{!isWidgetOpen && (
				<form className="form">
					<InputLabel
						id="environment"
						style={{
							marginBottom: "5px",
						}}
					>
						Environment
					</InputLabel>
					<ButtonGroup color="primary">
						{Object.keys(clientsByEnvironment).map((env, i) => (
							<Button
								key={i}
								onClick={() => setEnvironment(env)}
								className={environment === env ? "MuiButton-containedPrimary" : ""}
							>
								{env}
							</Button>
						))}
					</ButtonGroup>
					{environment && (
						<FormControl
							style={{
								marginTop: "10px",
								marginBottom: "5px",
							}}
						>
							<InputLabel id="client">Client</InputLabel>
							<Select
								labelId="client"
								value={client}
								name="client"
								onChange={(ev) => {
									setClient(ev.target.value);
									window.localStorage.setItem("clientActivities", ev.target.value);
								}}
							>
								{Object.keys(clientsByEnvironment[environment]).map((client, i) => (
									<MenuItem key={i} value={client}>
										{client.toUpperCase()}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					{environment === "PROD" && client && (
						<TextField
							id="clientSecret"
							label="Client Secret"
							name="clientSecret"
							value={clientSecret}
							onChange={(ev) => {
								setClientSecret(ev.target.value);
							}}
							required={environment === "PROD" && !!client}
							helperText="Required field"
						/>
					)}

					<TextField
						id="clientBookingId"
						label="Client Booking Id"
						name="clientBookingId"
						value={clientBookingId}
						onChange={handleInputChange}
						required
						helperText="Required field"
					/>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<DatePicker
							format="yyyy-MM-dd"
							label="Start Date"
							value={startDate}
							onChange={(value) => handleInputChange({ target: { name: "startDate", value } })}
							required
							helperText="Required field"
						/>
						<DatePicker
							format="yyyy-MM-dd"
							label="End Date"
							value={endDate}
							onChange={(value) => handleInputChange({ target: { name: "endDate", value } })}
						/>
					</MuiPickersUtilsProvider>
					<TextField
						id="location"
						label="Location"
						name="location"
						value={location}
						onChange={handleInputChange}
						required
						helperText="Required field"
					/>
					<TextField
						id="totalTickets"
						label="Total Tickets"
						value={totalTickets}
						name="totalTickets"
						onChange={handleInputChange}
						required
						helperText="Required field"
					/>
					<FormControl>
						<InputLabel id="businessTrip">Business Trip?</InputLabel>
						<Select labelId="businessTrip" value={businessTrip} name="businessTrip" onChange={handleInputChange}>
							<MenuItem value={null}>
								<em>None</em>
							</MenuItem>
							<MenuItem value={true}>Yes</MenuItem>
							<MenuItem value={false}>No</MenuItem>
						</Select>
					</FormControl>
					<FormControl>
						<InputLabel id="children">Children?</InputLabel>
						<Select labelId="children" value={withChildren} name="withChildren" onChange={handleInputChange}>
							<MenuItem value={null}>
								<em>None</em>
							</MenuItem>
							<MenuItem value={true}>Yes</MenuItem>
							<MenuItem value={false}>No</MenuItem>
						</Select>
					</FormControl>
					<TextField id="age1" label="Age 1" name="age1" value={ages.age1} onChange={handleInputChange} />
					<TextField id="age2" label="Age 2" name="age2" value={ages.age2} onChange={handleInputChange} />
					<TextField id="age3" label="Age 3" name="age3" value={ages.age3} onChange={handleInputChange} />
					<TextField id="age4" label="Age 4" name="age4" value={ages.age4} onChange={handleInputChange} />
					<TextField
						id="language"
						label="Language (en-US, zh-CN, es-ES...)"
						value={language}
						name="language"
						onChange={handleInputChange}
					/>
					<TextField
						id="currency"
						label="Currency (USD, CNY, EUR...)"
						value={currency}
						name="currency"
						onChange={handleInputChange}
					/>
					<TextField
						id="currencyExchange"
						label="Currency Exchange (with respect to 1 EUR)"
						value={currencyExchange}
						name="currencyExchange"
						onChange={handleInputChange}
					/>
					<TextField
						id="productId"
						label="Product Configuration Id (Resellers)"
						value={productId}
						name="productId"
						onChange={handleInputChange}
					/>

					<div className="btn-wrapper">
						<button className="update-btn" type="button" onClick={onActivitiesSubmit}>
							Get Activities
						</button>
						<button className="update-btn" type="reset" onClick={onReset}>
							Reset Filters
						</button>
						<button className="update-btn" type="button" onClick={onManageSubmit}>
							Manage my Booking
						</button>
					</div>
				</form>
			)}
		</>
	);
};

export default Activities;

import React, { useEffect } from "react";
import { encode as base64_encode } from "base-64";

import DateFnsUtils from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { transfersAppParameters } from "../../appParameters";
import { formatTransferDate } from "../../functions/formattedDate";
import useForm from "../../hooks/useForm";
import { Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { clientsByEnvironment } from "../../utils/clientsByEnvironment";

const {
	REACT_APP_DEV_TRANSFERS_TOKEN_URL,
	REACT_APP_PRE_TRANSFERS_TOKEN_URL,
	REACT_APP_PROD_TRANSFERS_TOKEN_URL,
	REACT_APP_CLIENT_ID,
	REACT_APP_SECRET_ID,
} = process.env;

async function getToken(baseUrl, credentials) {
	try {
		const response = await fetch(baseUrl + "?grant_type=client_credentials", {
			method: "POST",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				Authorization: "Basic " + credentials,
			},
		});

		return response.json();
	} catch (error) {
		console.error(error);
		return error;
	}
}

const Transfers = (props) => {
	const {
		isWidgetOpen,
		setIsWidgetOpen,
		postOrderCallback,
		confirmOrderCallback,
		getOrdersCallback,
		cancelOrderCallback,
		expiredTokenCallback,
	} = props;
	const [formValues, handleInputChange, reset] = useForm(transfersAppParameters);

	const [token, setToken] = React.useState("");
	const [environment, setEnvironment] = React.useState(window.localStorage.getItem("envTransfers") || "");
	const [authUrl, setAuthUrl] = React.useState("");
	const [client, setClient] = React.useState(window.localStorage.getItem("clientTransfers") || "");
	const [clientSecret, setClientSecret] = React.useState("");
	const [isMMB, setIsMMB] = React.useState(false);

	const {
		clientBookingId,
		language,
		currency,
		currencyExchange,
		airline,
		returnAirline,
		flightNumber,
		returnFlightNumber,
		outboundTripDateTime,
		returnTripDateTime,
		pickupLocation,
		travellers,
		productId,
	} = formValues;

	const getTransferSearchCallback = (transfers) => {
		console.log(transfers);
	};

	const mountTransferWidget = () => {
		window.RecoglobickTransfersWidget.unmount();
		window.RecoglobickTransfersWidget.mount({
			env: environment.toLowerCase(),
			token: `Bearer ${token}`,
			clientBookingId: clientBookingId.trim(""),
			language: language ? language.trim("") : "en-US",
			currency: currency ? currency.trim("") : "EUR",
			currencyExchange: currencyExchange ? currencyExchange.trim("") : 1,
			airline: airline ? airline.trim("") : null,
			returnAirline: returnAirline ? returnAirline.trim("") : null,
			flightNumber: flightNumber ? flightNumber.trim("") : null,
			returnFlightNumber: returnFlightNumber ? returnFlightNumber.trim("") : null,
			outboundTripDateTime: outboundTripDateTime ? formatTransferDate(outboundTripDateTime) : null,
			returnTripDateTime: returnTripDateTime ? formatTransferDate(returnTripDateTime) : null,
			pickupLocation: pickupLocation ? pickupLocation.trim("") : null,
			productId: productId ? productId.trim("") : null,
			travellers,
			gaTrackingCode: "test",
			postOrderCallback: postOrderCallback,
			getTransferSearchCallback: getTransferSearchCallback,
			confirmOrderCallback: confirmOrderCallback,
			expiredTokenCallback: expiredTokenCallback,
		});
	};

	const updateManagementWidget = () => {
		window.RecoglobickTransfersMmbWidget.unmount();
		window.RecoglobickTransfersMmbWidget.mount({
			env: environment.toLowerCase(),
			token: `Bearer ${token}`,
			bookingId: clientBookingId.trim(""),
			language: language ? language.trim("") : "en-US",
			currency: currency ? currency.trim("") : "EUR",
			currencyExchange: currencyExchange ? currencyExchange.trim("") : 1,
			gaTrackingCode: "test",
			productId: productId ? productId.trim("") : null,
			getOrdersCallback: getOrdersCallback,
			cancelOrderCallback: cancelOrderCallback,
			expiredTokenCallback: expiredTokenCallback,
		});
	};

	useEffect(() => {
		window.localStorage.setItem("envTransfers", environment);
		switch (environment) {
			case "DEV":
				setAuthUrl(REACT_APP_DEV_TRANSFERS_TOKEN_URL);
				break;
			case "PRE":
				setAuthUrl(REACT_APP_PRE_TRANSFERS_TOKEN_URL);
				break;
			case "PROD":
				setAuthUrl(REACT_APP_PROD_TRANSFERS_TOKEN_URL);
				break;
			default:
				break;
		}
	}, [environment]);

	const setEncodedCredentials = () => {
		if (environment === "PROD" && !clientSecret) return;

		const clientId = clientsByEnvironment[environment][client].clientId || REACT_APP_CLIENT_ID;
		const secret = clientSecret || clientsByEnvironment[environment][client].secretId || REACT_APP_SECRET_ID;
		let encodedCredentials = base64_encode(clientId + ":" + secret);

		return encodedCredentials;
	};

	const getApiTokens = () => {
		getToken(authUrl, setEncodedCredentials())
			.then((response) => {
				setToken(response.access_token);
			})
			.catch(console.error);
	};

	const onTransfersSubmit = () => {
		if (!clientBookingId) return;

		setIsMMB(false);
		getApiTokens();
	};

	const onManageSubmit = () => {
		if (!clientBookingId) return;

		setIsMMB(true);
		getApiTokens();
	};

	useEffect(() => {
		if (!isMMB) {
			if (!token || !clientBookingId) return;

			setIsWidgetOpen(true);
			mountTransferWidget();
		} else {
			if (!token || !clientBookingId) return;

			setIsWidgetOpen(true);
			updateManagementWidget();
		}
	}, [token]);

	const onReset = () => {
		setEnvironment("");
		setClient("");
		setClientSecret("");
		reset(transfersAppParameters);
	};

	return (
		<>
			{!isWidgetOpen && (
				<form className="form">
					<InputLabel
						id="environment"
						style={{
							marginBottom: "5px",
						}}
					>
						Environment
					</InputLabel>
					<ButtonGroup color="primary">
						{Object.keys(clientsByEnvironment).map((env, i) => (
							<Button
								key={i}
								onClick={() => setEnvironment(env)}
								className={environment === env ? "MuiButton-containedPrimary" : ""}
							>
								{env}
							</Button>
						))}
					</ButtonGroup>
					{environment && (
						<FormControl
							style={{
								marginTop: "10px",
								marginBottom: "5px",
							}}
						>
							<InputLabel id="client">Client</InputLabel>
							<Select
								labelId="client"
								value={client}
								name="client"
								onChange={(ev) => {
									setClient(ev.target.value);
									window.localStorage.setItem("clientTransfers", ev.target.value);
								}}
							>
								{Object.keys(clientsByEnvironment[environment]).map((client, i) => (
									<MenuItem key={i} value={client}>
										{client.toUpperCase()}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					{environment === "PROD" && client && (
						<TextField
							id="clientSecret"
							label="Client Secret"
							name="clientSecret"
							value={clientSecret}
							onChange={(ev) => {
								setClientSecret(ev.target.value);
							}}
							required={environment === "PROD" && !!client}
							helperText="Required field"
						/>
					)}

					<TextField
						id="clientBookingId"
						label="Client Booking Id"
						name="clientBookingId"
						value={clientBookingId}
						onChange={handleInputChange}
						helperText="Required field"
						required
					/>
					<TextField
						id="pickupLocation"
						label="Pickup Location"
						name="pickupLocation"
						value={pickupLocation}
						onChange={handleInputChange}
					/>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<DateTimePicker
							ampm={false}
							format="yyyy-MM-dd HH:mm"
							label="OutBound Trip Date"
							value={outboundTripDateTime}
							onChange={(value) =>
								handleInputChange({
									target: { name: "outboundTripDateTime", value },
								})
							}
						/>
					</MuiPickersUtilsProvider>
					<TextField
						id="airline"
						label="Outbound Airline"
						name="airline"
						value={airline}
						onChange={handleInputChange}
					/>
					<TextField
						id="flightNumber"
						label="Outbound Flight Number"
						name="flightNumber"
						value={flightNumber}
						onChange={handleInputChange}
					/>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<DateTimePicker
							ampm={false}
							format="yyyy-MM-dd HH:mm"
							label="Return Trip Date"
							value={returnTripDateTime}
							onChange={(value) =>
								handleInputChange({
									target: { name: "returnTripDateTime", value },
								})
							}
						/>
					</MuiPickersUtilsProvider>
					<TextField
						id="returnAirline"
						label="Return Airline"
						name="returnAirline"
						value={returnAirline}
						onChange={handleInputChange}
					/>
					<TextField
						id="returnFlightNumber"
						label="Return Flight Number"
						name="returnFlightNumber"
						value={returnFlightNumber}
						onChange={handleInputChange}
					/>
					<TextField
						id="language"
						label="Language (en-US, zh-CN, es-ES...)"
						value={language}
						name="language"
						onChange={handleInputChange}
					/>
					<TextField
						id="currency"
						label="Currency (USD, CNY, EUR...)"
						value={currency}
						name="currency"
						onChange={handleInputChange}
					/>
					<TextField
						id="currencyExchange"
						label="Currency Exchange (with respect to 1 EUR)"
						value={currencyExchange}
						name="currencyExchange"
						onChange={handleInputChange}
					/>
					<TextField
						id="productId"
						label="Product Configuration Id (Resellers)"
						value={productId}
						name="productId"
						onChange={handleInputChange}
					/>

					<div className="btn-wrapper">
						<button className="update-btn" type="button" onClick={onTransfersSubmit}>
							Get Transfers
						</button>
						<button className="update-btn" type="reset" onClick={onReset}>
							Reset Filters
						</button>
						<button className="update-btn" type="button" onClick={onManageSubmit}>
							Manage my Booking
						</button>
					</div>
				</form>
			)}
		</>
	);
};

export default Transfers;

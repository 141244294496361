import { useState } from "react";

const CONST_AGE = "age";

const useForm = (initialState = {}) => {
  const [values, setValues] = useState(initialState);

  const handleInputChange = ({ target }) => {
    if (target.name.startsWith(CONST_AGE)) {
      setValues({
        ...values,
        ages: {
          ...values.ages,
          [target.name]: target.value.trim(""),
        },
      });
    } else {
      setValues({
        ...values,
        [target.name]: target.value,
      });
    }
  };

  const reset = (initialValue) => {
    setValues(initialValue);
  };

  return [values, handleInputChange, reset];
};

export default useForm;

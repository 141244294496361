export const formatActivitiesDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const formatTransferDate = (value) => {
  const date = value.toLocaleDateString("fr-CA");
  const time = value.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return `${date}T${time}`;
};

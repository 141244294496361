export async function getToken(baseUrl, credentials) {
	try {
		const response = await fetch(baseUrl + "?grant_type=client_credentials", {
			method: "POST",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				Authorization: "Basic " + credentials,
			},
		});

		return response.json();
	} catch (error) {
		console.error(error);
		return error;
	}
}
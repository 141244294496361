const {
	REACT_APP_DEV_CLIENT_ID,
	REACT_APP_DEV_SECRET_ID,
	REACT_APP_PRE_CLIENT_ID,
	REACT_APP_PRE_SECRET_ID,
	REACT_APP_PRE_IBERIA_CLIENT_ID,
	REACT_APP_PRE_IBERIA_SECRET_ID,
	REACT_APP_PROD_IBERIA_CLIENT_ID,
	REACT_APP_PRE_IBERIAPAY_CLIENT_ID,
	REACT_APP_PRE_IBERIAPAY_SECRET_ID,
	REACT_APP_PROD_IBERIAPAY_CLIENT_ID,
	REACT_APP_PRE_DOCO_WEB_CLIENT_ID,
	REACT_APP_PRE_DOCO_WEB_SECRET_ID,
	REACT_APP_PROD_DOCO_WEB_CLIENT_ID,
	REACT_APP_PRE_DOCO_APP_CLIENT_ID,
	REACT_APP_PRE_DOCO_APP_SECRET_ID,
	REACT_APP_PROD_DOCO_APP_CLIENT_ID,
	REACT_APP_PRE_IBEROSTAR_APP_CLIENT_ID,
	REACT_APP_PRE_IBEROSTAR_APP_SECRET_ID,
	REACT_APP_PROD_IBEROSTAR_APP_CLIENT_ID,
	REACT_APP_PRE_THEHOTELSNETWORK_APP_CLIENT_ID,
	REACT_APP_PRE_THEHOTELSNETWORK_APP_SECRET_ID,
	REACT_APP_PROD_THEHOTELSNETWORK_APP_CLIENT_ID,
} = process.env;

export const clientsByEnvironment = {
	DEV: {
		globick: {
			clientId: REACT_APP_DEV_CLIENT_ID,
			secretId: REACT_APP_DEV_SECRET_ID,
		},
	},
	PRE: {
		globick: { clientId: REACT_APP_PRE_CLIENT_ID, secretId: REACT_APP_PRE_SECRET_ID },
		iberia: { clientId: REACT_APP_PRE_IBERIA_CLIENT_ID, secretId: REACT_APP_PRE_IBERIA_SECRET_ID },
		iberiaPay: { clientId: REACT_APP_PRE_IBERIAPAY_CLIENT_ID, secretId: REACT_APP_PRE_IBERIAPAY_SECRET_ID },
		docoWeb: { clientId: REACT_APP_PRE_DOCO_WEB_CLIENT_ID, secretId: REACT_APP_PRE_DOCO_WEB_SECRET_ID },
		docoApp: { clientId: REACT_APP_PRE_DOCO_APP_CLIENT_ID, secretId: REACT_APP_PRE_DOCO_APP_SECRET_ID },
		iberostar: { clientId: REACT_APP_PRE_IBEROSTAR_APP_CLIENT_ID, secretId: REACT_APP_PRE_IBEROSTAR_APP_SECRET_ID },
		theHotelsNetwork: {
			clientId: REACT_APP_PRE_THEHOTELSNETWORK_APP_CLIENT_ID,
			secretId: REACT_APP_PRE_THEHOTELSNETWORK_APP_SECRET_ID,
		},
	},
	PROD: {
		iberia: { clientId: REACT_APP_PROD_IBERIA_CLIENT_ID },
		iberiaPay: { clientId: REACT_APP_PROD_IBERIAPAY_CLIENT_ID },
		docoWeb: { clientId: REACT_APP_PROD_DOCO_WEB_CLIENT_ID },
		docoApp: { clientId: REACT_APP_PROD_DOCO_APP_CLIENT_ID },
		iberostar: { clientId: REACT_APP_PROD_IBEROSTAR_APP_CLIENT_ID },
		theHotelsNetwork: { clientId: REACT_APP_PROD_THEHOTELSNETWORK_APP_CLIENT_ID },
	},
};
import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";

import TabPanel from "./UI/TabPanel/TabPanel";
import { CustomTabs, tabProps } from "./UI/TabPanel/tabPanelConfig";
import Activities from "./views/activities/Activities";
import Transfers from "./views/transfers/Transfers";

import "./App.css";

const App = () => {
  const [selectedTab, setSelectedTab] = useState(
    JSON.parse(localStorage.getItem("selectedTab")) || 0
  );
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);

  const handleChange = (event, tabIndex) => {
    setSelectedTab(tabIndex);
    localStorage.setItem("selectedTab", tabIndex);
  };

  return (
    <div className="App">
      <header className="App-header" />
      <div className="body">
        <div className="booking-step" />
        <h1 className="customize-title">Personaliza y completa tu viaje</h1>
        {!isWidgetOpen && (
          <div className="container">
            <AppBar position="static" elevation={0}>
              <CustomTabs
                value={selectedTab}
                onChange={handleChange}
                aria-label="simple-tabpanel"
                centered
              >
                <Tab label="Activities" {...tabProps(0)} />
                <Tab label="Transfers" {...tabProps(1)} />
              </CustomTabs>
            </AppBar>
            <TabPanel value={selectedTab} index={0}>
              <Activities
                isWidgetOpen={isWidgetOpen}
                setIsWidgetOpen={setIsWidgetOpen}
                postOrderCallback={console.log}
                confirmOrderCallback={console.log}
                getOrdersCallback={console.log}
                cancelOrderCallback={console.log}
                expiredTokenCallback={console.log}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <Transfers
                isWidgetOpen={isWidgetOpen}
                setIsWidgetOpen={setIsWidgetOpen}
                postOrderCallback={console.log}
                confirmOrderCallback={console.log}
                getOrdersCallback={console.log}
                cancelOrderCallback={console.log}
                expiredTokenCallback={console.log}
              />
            </TabPanel>
          </div>
        )}
        <div id="recoglobick-activities"></div>
        <div id="recoglobick-activities-mmb"></div>
        <div id="recoglobick-transfers"></div>
        <div id="recoglobick-transfers-mmb"></div>
      </div>
    </div>
  );
};

export default App;
